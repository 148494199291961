import { Button, ButtonProps } from "reactstrap";
import styles from "./TextButton.module.scss";

const TextButton: React.FC<ButtonProps> = (props) => (
  <Button {...props} className={`${styles.button} ${props.className}`}>
    {props.children}
  </Button>
);

export default TextButton;
