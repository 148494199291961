import { HTMLAttributes } from "react";

interface HrProps extends HTMLAttributes<HTMLHRElement> {
  label?: string;
  /** select a color from variables. Default is "gray-600" **/
  color?: string;
}

const Hr: React.FC<HrProps> = ({ label, color = "gray-600", ...rest }) => {
  return (
    <div className="position-relative d-flex w-100 justify-content-center align-items-center my-5">
      <hr {...rest} className={`${rest.className} w-100 text-${color}`} />
      {label && (
        <span className="position-absolute m-auto w-auto bg-white px-4 text-${color}">
          {label}
        </span>
      )}
    </div>
  );
};

export default Hr;
