import React from "react";

type TranslationLinkTextProps = {
  to: string;
  title: string;
  children?: React.ReactElement;
};

const TranslationLinkText: React.FC<TranslationLinkTextProps> = (props) => {
  return (
    <a
      href={props.to || "#"}
      target="_blank"
      title={props.title || ""}
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

export default TranslationLinkText;
