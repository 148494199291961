import routes, { Route } from "./routes";

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[`/§\":,'´?!@#\$%\^&\*\)\(+=._-])(?=.{8,})/;

export const getRouteByPathname = (pathname: string): Route | null => {
  const [_, route] = Object.entries(routes).find(
    ([_, value]) => value.path === pathname,
  ) ?? [null, null];

  return route;
};

export const filterObjectForTruthyValues = (values: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(values).filter(([_, value]) => value));
