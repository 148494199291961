import { useEffect, useState } from "react";
import variables from "../../../styles/variables.module.scss";
import useWindowSize from "./useWindowSize";

export enum WidnowSizeBreakpoints {
  XXL,
  XL,
  LG,
  MD,
  SM,
  XS,
}
const getNumericalValueFromPxString = (value: string) =>
  parseInt(value.split("px")[0]);

const useWindowSizeBreakpoints = () => {
  const { width } = useWindowSize();

  if (!width) return undefined;

  if (width > getNumericalValueFromPxString(variables["grid-breakpoint-xxl"])) {
    return WidnowSizeBreakpoints.XXL;
  } else if (
    width > getNumericalValueFromPxString(variables["grid-breakpoint-xl"])
  ) {
    return WidnowSizeBreakpoints.XL;
  } else if (
    width > getNumericalValueFromPxString(variables["grid-breakpoint-lg"])
  ) {
    return WidnowSizeBreakpoints.LG;
  } else if (
    width > getNumericalValueFromPxString(variables["grid-breakpoint-md"])
  ) {
    return WidnowSizeBreakpoints.MD;
  } else if (
    width > getNumericalValueFromPxString(variables["grid-breakpoint-md"])
  ) {
    return WidnowSizeBreakpoints.SM;
  } else {
    return WidnowSizeBreakpoints.XS;
  }
};

export default useWindowSizeBreakpoints;
