// combination of Formik Field component and bootstrap typeahead fields
// ensures formik functionality while benefiting of bootstrap validation styling

import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { useCallback, useMemo, useState } from "react";
import { Typeahead as BootsrapTypeahead } from "react-bootstrap-typeahead";
import { FormFeedback, Label } from "reactstrap";
import { TypeaheadComponentProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import { useTranslation } from "next-i18next";

type InputProps = TypeaheadComponentProps & {
  name: string;
  options: string[];
  initialValue?: string | null;
  label?: string | null;
  required?: boolean;
  onTypeaheadChange: (value: string) => void;
  onTypeaheadBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  validate: {
    touched: FormikTouched<Record<string, string>>;
    errors: FormikErrors<Record<string, string>>;
    values: FormikValues;
  };
};

const Typeahead: React.FC<InputProps> = ({
  name,
  className,
  validate,
  options,
  initialValue,
  label,
  required,
  onTypeaheadChange,
  onTypeaheadBlur,
  ...rest
}) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState(initialValue ?? "");
  const [blured, setBlured] = useState(false);
  const getValidationClass = useCallback(
    (validClass: string | null, invalidClass: string | null) => {
      if (validate && validate.values && validate.errors && blured) {
        if (!validate.values[name] || validate.errors[name]) {
          return invalidClass;
        } else {
          return validClass;
        }
      }
      return null;
    },
    [blured, name, validate],
  );

  const fieldValidationClass = useMemo(() => {
    return getValidationClass("is-valid", "is-invalid");
  }, [getValidationClass]);

  const feedbackValidationClass = useMemo(() => {
    return getValidationClass(null, "invalid-feedback");
  }, [getValidationClass]);

  return (
    <>
      <Label>{label}{required && '*'}</Label>
      <BootsrapTypeahead
        labelKey={name}
        id={name}
        className={`${className} ${
          !value && "text-muted"
        } p-0 form-control valid ${fieldValidationClass}`}
        {...rest}
        onChange={([value]) => {
          setValue((value as string) ?? "");
          onTypeaheadChange && onTypeaheadChange(value as string);
        }}
        defaultInputValue={initialValue ?? ""}
        options={options}
        onBlur={(e) => {
          onTypeaheadChange && onTypeaheadChange(e.target.value);
          setBlured(true);
          onTypeaheadBlur && onTypeaheadBlur(e);
        }}
        emptyLabel={t("typeahead.empty")}
      />
      {feedbackValidationClass && (
        <FormFeedback
          valid={!!validate.errors[name]}
          className={`valid ${feedbackValidationClass}`}
        >
          {validate.errors[name]}
        </FormFeedback>
      )}
    </>
  );
};

export default Typeahead;
