import moment from "moment";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Button } from "reactstrap";
import useWindowSizeBreakpoints, {
  WidnowSizeBreakpoints,
} from "../../../pages/account/hooks/useWindowSizeBreakpoints";
import { UserAttributes } from "../../../services/auth";
import variables from "../../../styles/variables.module.scss";
import styles from "./Calendar.module.scss";

type CalendarEntryProps = {
  userAttributes?: UserAttributes;
  calendlyUrl: string;
  dateSelection?: Date;
  fallback?: React.ReactNode;
  onSuccess?: (uri: string) => void;
};

const Calendar: React.FC<CalendarEntryProps> = ({
  userAttributes,
  calendlyUrl,
  dateSelection,
  fallback,
  onSuccess,
}) => {
  const [isScheduling, setIsScheduling] = useState(!dateSelection);
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsScheduling(!dateSelection);
  }, [dateSelection]);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      onSuccess && onSuccess(e.data.payload.event.uri);
    },
  });

  const breakpoint = useWindowSizeBreakpoints();

  const calendelyHeight = useMemo(() => {
    switch (breakpoint) {
      case WidnowSizeBreakpoints.XS:
      case WidnowSizeBreakpoints.SM:
        return "870px";
      case WidnowSizeBreakpoints.MD:
        return "890px";
      case WidnowSizeBreakpoints.LG:
        return "980px";
      default:
        return "780px";
    }
  }, [breakpoint]);

  return (
    <div className="mt-md-n10">
      <div className={styles.calendarWrapper}>
        {isScheduling ? (
          <>
            <InlineWidget
              prefill={{
                email: userAttributes?.email ?? "",
                name: userAttributes?.name ?? "",
                date: dateSelection,
              }}
              styles={{ height: calendelyHeight }}
              pageSettings={{
                primaryColor: variables.primary.replace("#", ""),
              }}
              url={calendlyUrl}
            />
            {fallback}
            {dateSelection && (
              <div className="d-flex justify-content-center my-3">
                <div>
                  {t("reschedule.cancel")}
                  <strong>
                    {moment(dateSelection).format("DD.MM.YYYY")},{" "}
                    {moment(dateSelection).format("HH:mma")} -{" "}
                    {moment(dateSelection).format("HH:mma")}
                  </strong>
                  <div className="d-flex justify-content-center my-6">
                    <Button
                      className="mx-2"
                      outline
                      color="primary"
                      onClick={() => setIsScheduling(false)}
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center my-10">
            <div>
              {t("reschedule.prompt")}{" "}
              <strong>
                {moment(dateSelection).format("DD.MM.YYYY")},{" "}
                {moment(dateSelection).format("HH:mma")} -{" "}
                {moment(dateSelection).format("HH:mma")}
              </strong>
              <div className="d-flex justify-content-center my-6">
                <Button
                  className="mx-2"
                  outline
                  color="primary"
                  onClick={() => setIsScheduling(true)}
                >
                  {t("reschedule.label")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
