/* eslint-disable react/display-name */
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Alert } from "reactstrap";
import * as Sentry from "@sentry/nextjs";

type AlertConfig = {
  type: AlertTypes;
  /** Key sent to Sentry to identify the issue **/
  key: string;
  /** Message shown to the user. Not used for any tracking **/
  message: string;
};

export type AlertDispatch = Dispatch<SetStateAction<AlertConfig | null>>;
export type AlertSetter = {
  setAlert: AlertDispatch;
};

export enum AlertTypes {
  SUCESS,
  ERROR,
  WARNING,
  INFO,
}

const withAlerts =
  <P extends object>(Component: React.FC<P>): React.FC<P> =>
  ({ ...props }) => {
    const [alert, setAlert] = useState<AlertConfig | null>(null);

    const alertColor = useMemo(() => {
      switch (alert?.type) {
        case AlertTypes.SUCESS:
          return "primary";
        case AlertTypes.ERROR:
          return "danger";
        case AlertTypes.WARNING:
          return "warn";
        case AlertTypes.INFO:
          return "info";
      }
    }, [alert]);

    useEffect(() => {
      if (alert?.type === AlertTypes.ERROR) {
        Sentry.captureException(new Error(alert.key));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert?.key]);

    return (
      <>
        {alert && (
          <Alert color={alertColor} className="mt-6">
            {alert.message}
          </Alert>
        )}
        <Component {...props} setAlert={setAlert} />
      </>
    );
  };

export default withAlerts;
