import { FilePondFile, FilePondInitialFile } from "filepond";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import {
  getFileNameFromUserPath,
  removeFile as removeS3File,
  uploadFile,
  getUserFilePath,
} from "../../../services/s3";
import { FormFeedback } from "reactstrap";
import { getUserIdentityId } from "services/auth";

export type Validation = {
  touched: Record<string, any>;
  errors: Record<string, any>;
  values: Record<string, any>;
};

type UploadProps = {
  name: string;
  initialFiles?: string[];
  overwriteFileName?: string;
  onUpload?: (key: string) => void;
  onRemove?: (key: string) => void;
  required?: boolean;
  validate?: Validation;
};

registerPlugin(FilePondPluginFileValidateSize);

interface FilepondInput extends FilePondInitialFile {
  filename: string;
}

const Upload: React.FC<UploadProps> = ({
  name,
  initialFiles,
  overwriteFileName,
  validate = { values: {}, errors: {}, touched: {} } as Validation,
  onUpload,
  onRemove,
}) => {
  const { t } = useTranslation("common");
  const [files, setFiles] = useState<Array<FilepondInput | FilePondFile>>(
    initialFiles
      ? initialFiles.map((fileName) => ({
          filename: fileName,
          source: fileName,
          options: { type: "limbo" },
        }))
      : [],
  );

  const removeFile = useCallback(
    async (filePath: string | undefined) => {
      if (filePath) {
        const identityId = await getUserIdentityId();

        const absoluteFilePathToBeRemoved = filePath.includes(identityId)
          ? filePath
          : `${identityId}/${filePath}`;

        try {
          await removeS3File(getFileNameFromUserPath(filePath));
          setFiles(
            files.filter((file) => {
              const absoluteFilePath = file.filename.includes(identityId)
                ? file.filename
                : `${identityId}/${file.filename}`;

              return absoluteFilePath !== absoluteFilePathToBeRemoved;
            }),
          );
          onRemove && onRemove(absoluteFilePathToBeRemoved);
        } catch (_e) {
          const e = _e as Error;
          console.error("Error uploading file: ", e);
        }
      }
    },
    [files, onRemove],
  );

  const getValidationClass = useCallback(
    (validClass: string | null, invalidClass: string | null) => {
      if (validate.errors && validate.touched && validate.touched[name]) {
        if (!validate.values[name] || validate.errors[name]) {
          return invalidClass;
        } else {
          return validClass;
        }
      }
      return null;
    },
    [name, validate.errors, validate.values, validate.touched],
  );

  const fieldValidationClass = useMemo(() => {
    return getValidationClass("is-valid", "is-invalid");
  }, [getValidationClass, name]);

  const feedbackValidationClass = useMemo(() => {
    return getValidationClass(null, "invalid-feedback");
  }, [getValidationClass]);

  return (
    <>
      <div className={`form-control valid ${fieldValidationClass} p-0`}>
        <FilePond
          className="bg-gray-200 mb-0"
          name={name}
          credits={false}
          allowMultiple={true}
          files={files as FilePondInitialFile[]}
          maxParallelUploads={1}
          onprocessfilerevert={(removedFile) => {
            // revert events pass an undefined filePath when deleting files directly after uploading them.
            // this serves as a fallback for such cases
            if (files.find((file) => file.filename === removedFile.filename)) {
              removeFile(removedFile.filename);
            }
          }}
          onupdatefiles={async (updatedFiles) => {
            // don't imediately update files in case a file got removed
            // onupdatefiles for updating gets triggered after the upload is done
            // onupdatefiles for removing is triggered right away
            updatedFiles.length >= files.length && setFiles(updatedFiles);
          }}
          labelIdle={t("fileUpload.labelIdle")}
          labelInvalidField={t("fileUpload.labelInvalidField")}
          labelFileWaitingForSize={t("fileUpload.labelFileWaitingForSize")}
          labelFileSizeNotAvailable={t("fileUpload.labelFileSizeNotAvailable")}
          labelFileLoading={t("fileUpload.labelFileLoading")}
          labelFileLoadError={t("fileUpload.labelFileLoadError")}
          labelFileProcessing={t("fileUpload.labelFileProcessing")}
          labelFileProcessingComplete={t(
            "fileUpload.labelFileProcessingComplete",
          )}
          labelFileProcessingAborted={t(
            "fileUpload.labelFileProcessingAborted",
          )}
          labelFileProcessingError={t("fileUpload.labelFileProcessingError")}
          labelFileProcessingRevertError={t(
            "fileUpload.labelFileProcessingRevertError",
          )}
          labelFileRemoveError={t("fileUpload.labelFileRemoveError")}
          labelTapToCancel={t("fileUpload.labelTapToCancel")}
          labelTapToRetry={t("fileUpload.labelTapToRetry")}
          labelTapToUndo={t("fileUpload.labelTapToUndo")}
          labelButtonRemoveItem={t("fileUpload.labelButtonRemoveItem")}
          labelButtonAbortItemLoad={t("fileUpload.labelButtonAbortItemLoad")}
          labelButtonRetryItemLoad={t("fileUpload.labelButtonRetryItemLoad")}
          labelButtonAbortItemProcessing={t(
            "fileUpload.labelButtonAbortItemProcessing",
          )}
          labelButtonUndoItemProcessing={t(
            "fileUpload.labelButtonUndoItemProcessing",
          )}
          labelButtonRetryItemProcessing={t(
            "fileUpload.labelButtonRetryItemProcessing",
          )}
          labelButtonProcessItem={t("fileUpload.labelButtonProcessItem")}
          maxFiles={10}
          allowFileSizeValidation
          maxTotalFileSize="50MB"
          labelMaxFileSizeExceeded={t("fileUpload.labelMaxFileSizeExceeded")}
          labelMaxFileSize={t("fileUpload.labelMaxFileSize")}
          labelMaxTotalFileSizeExceeded={t(
            "fileUpload.labelMaxTotalFileSizeExceeded",
          )}
          labelMaxTotalFileSize={t("fileUpload.labelMaxTotalFileSize")}
          server={{
            revert: removeFile,
            remove: removeFile,
            process: async (
              _fieldName,
              file,
              _metadata,
              load,
              error,
              progress,
              _abort,
            ) => {
              try {
                const filePath = await getUserFilePath(file.name);

                await uploadFile(
                  { ...file, name: overwriteFileName ?? file.name },
                  ({ loaded, total }) => {
                    progress(true, loaded, total);
                  },
                );

                onUpload && onUpload(filePath);
                load({ source: filePath });
              } catch (_e) {
                const e = _e as Error;
                console.error("Error uploading file: ", e);
                error(e.message);
              }
            },
          }}
        />
      </div>
      {feedbackValidationClass && (
        <FormFeedback
          valid={!!validate.errors[name]}
          className={`valid d-block ${feedbackValidationClass}`}
        >
          {validate.errors[name]}
        </FormFeedback>
      )}
    </>
  );
};

export default Upload;
