import { Auth, Storage } from "aws-amplify";

export const uploadFile: (
  file: Blob & { name: string },
  progressCallback?: (data: { loaded: number; total: number }) => void,
) => Promise<{ key: string }> = async (file, progressCallback) =>
  Storage.put(file.name, file, {
    level: "private",
    customPrefix: {
      private: "private/",
    },
    progressCallback,
  });

export const removeFile = async (path: string) => {
  return Storage.remove(path, { level: "private" });
};

export const readFile = async (path: string) => {
  return Storage.get(path, { download: false, level: "private" });
};

export const getUserFilePath: (key: string) => Promise<string> = async (
  key,
) => {
  const { identityId } = await Auth.currentUserCredentials();
  return `${identityId}/${key}`;
};

export const getFileNameFromUserPath: (path: string) => string = (path) => {
  const pathElements = path.split("/");
  pathElements.shift();
  return pathElements.join("/");
};
